import React from "react"
import Layout from "../components/layout"
import { Container, Row, Col, Image } from 'react-bootstrap'
import Header from "../../src/img/header.jpg"
import Hanisch from "../../src/img/hanisch.jpg"
import GoogleMaps from "../../src/img/gm.png"
import Map from "../../src/img/map.png"
import { Card } from "../components/card"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/global.scss'

export default () => (
    <Layout>
        <Container className="position-relative d-md-flex justify-content-center align-items-center px-0 mb-5" fluid>
            <Image src={Header} fluid />
            <Container className="position-md-absolute text-center">
                <Row>
                    <Col xs="12" className="d-none d-md-block">
                        <h2 className="text-white mb-3">
                            Dr. Ernstfried Hanisch
                        </h2>
                        <h1 className="text-white mb-0">
                            Praxis für Psychotherapie
            </h1>
                    </Col>
                    <Col xs="12" className="d-md-none mt-5">
                        <h2 className="mb-3">
                            Dr. Ernstfried Hanisch
            </h2>
                        <h1 className="mb-0">
                            Praxis für Psychotherapie
            </h1>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container>
            <Row id="about" className="mb-5">
                <Col xs="12">
                    <h3 className="mb-4">
                        Herzlich willkommen
          </h3>
                </Col>
                <Col xs="12" md="3" className="order-md-2 mb-3 mb-md-0">
                    <Image src={Hanisch} fluid />
                </Col>
                <Col xs="12" md="9" className="order-md-1">
                    <p className="mb-3">
                        Als Psychologischer Psychotherapeut habe ich mehr als 45 Jahre Berufserfahrung und praktiziere in München mit Kassenzulassung. Ich bin staatlich anerkannter Ausbilder für Psychotherapeuten, Seminarleiter, Lehrtherapeut und Supervisor.
          </p>
                    <p className="mb-4">
                        Seit mehr als 37 Jahren arbeite ich als Dozent am Verhaltenstherapeutischen Ausbildungsinstitut München (AIM), dem ich viele Jahre als Vorstandsmitglied angehörte.
          </p>
                </Col>
                <Col xs="12" className="order-md-3">
                    <ul className="cv">
                        {/* <li>Assistent am Max-Planck-Institut für Psychatrie München</li> */}
                        {/* <li>Psychotherapeut an der bundesweit ersten verhaltenstherapeutischen Psychosomatischen Klinik in Windach</li> */}
                        <li>Betrieb der eigenen Psychotherapiepraxis in München</li>
                        <li>
                            Seminarleiter an verschiedenen psychotherapeutischen Ausbildungsinstituten in München
              <ul>
                                <li>Verein zur Förderung der Klinischen Verhaltenstherapie (VfkV)</li>
                                <li>Institut für Therapieforschung (IFT)</li>
                                <li>Centrum für Integrative Psychotherapie (CIP)</li>
                            </ul>
                        </li>
                        <li>Selbsterfahrung für Ärzte und Psychologen und Interaktionsbezogene Fallarbeit („Verhaltenstherapeutische Balintgruppe“), von der Bayerischen Ärztekammer anerkannt</li>
                        <li>Supervisorenausbildung im VfkV</li>
                    </ul>
                </Col>
            </Row>
            <Row id="therapy" className="mb-5">
                <Col xs="12">
                    <h3 className="mb-4">
                        Therapie Angebot
          </h3>
                </Col>
                <Col xs="12" md="3" className="mb-4">
                    <Card title="Einzeltherapie"></Card>
                </Col>
                <Col xs="12" md="3" className="mb-4">
                    <Card title="Gruppentherapie"></Card>
                </Col>
                <Col xs="12" md="3" className="mb-4">
                    <Card title="Paartherapie"></Card>
                </Col>
                <Col xs="12">
                    <p className="mb-4">
                        Mein Therapieansatz bei psychischen und psychosomatischen Erkrankungen ist integrativ, lösungsorientiert und dient der Entwicklung individueller Stärken und Erreichung persönlicher Lebensziele. Grundlage ist die wissenschaftliche Verhaltentherapie.
                    </p>
                </Col>
                <Col xs="12">
                    <h4 className="mb-3">
                        Behandlungsschwerpunkte
                    </h4>
                    <ul>
                        <li>Ängste</li>
                        <li>Depressionen</li>
                        <li>Burn-out-Syndrome</li>
                        <li>Selbstwertprobleme</li>
                        <li>Schwierigkeiten im Kontakt mit anderen Menschen</li>
                        <li>Partnerkonflikte</li>
                    </ul>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col xs="12">
                    <h3 className="mb-4">
                        Aus- und Weiterbildungsangebot
          </h3>
                </Col>
                <Col xs="12" md="3" className="mb-4">
                    <Card title="Selbsterfahrung"></Card>
                </Col>
                <Col xs="12" md="3" className="mb-4">
                    <Card title="Interaktionsbezogene Fallarbeit"></Card>
                </Col>
                <Col xs="12" md="3" className="mb-4">
                    <Card title="Einzel- und Gruppensupervision"></Card>
                </Col>
                <Col xs="12" md="3" className="mb-4">
                    <Card title="Ausbildungsseminare"></Card>
                </Col>
                <Col xs="12">
                    <p>
                        Neben Selbsterfahrung und interaktionsbezogener Fallarbeit biete ich Einzel- und Gruppen-Supervision an. Zusätzlich gebe ich Ausbildungsseminare am AIM und CIP.
          </p>
                </Col>
            </Row>
            <Row id="contact" className="mb-5">
                <Col xs="12">
                    <h3 className="mb-4">
                        Kontakt und Anfahrt
          </h3>
                </Col>
                <Col xs="12" md="6" className="order-md-2 mb-3 mb-md-0">
                    <a href="https://www.openstreetmap.org/#map=19/48.11530/11.52482" target="_blank"><Image src={Map} fluid style={{maxHeight: "20rem"}} /></a>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2663.735484058491!2d11.52299311641143!3d48.11533817922161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479dd8c612191511%3A0x896cf7e90bb745b2!2sNovalisstra%C3%9Fe%204%2C%2081369%20M%C3%BCnchen!5e0!3m2!1sde!2sde!4v1605019724647!5m2!1sde!2sde" width="350" height="300" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                </Col>
                <Col xs="12" md="6" className="order-md-1">
                    <p>
                        Novalisstr. 4<br />
            81369 München<br />
            Tel.: 089-531654<br />
            Mobil: 0152-34564076<br />
            Mail: dr.e.hanisch@web.de<br />
                    </p>
                </Col>
            </Row>
        </Container>
    </Layout>
)
