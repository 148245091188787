import React from "react"

export function Card(props) {
  return (
    <div className="card card-sq flex-row align-items-center justify-content-center shadow border-0">
      <h5 className="text-white text-center">
        { props.title }
      </h5>
    </div>
  )
}